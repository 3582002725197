import React, { useMemo } from 'react';
import pluralize from 'shared/utils/pluralize';

import styles from './BulkActionsHeader.module.css';
import Button from './Button';
import Checkbox from './Checkbox';

type Props = {
  selectedIds: string[];
  setSelectedIds: (ids: string[]) => void;
  resourceName: string;
  onCheckedChange: () => void;
  isChecked: boolean;
  isMinusIcon: boolean;
  children: React.ReactNode;
};

const BulkActionsHeader = React.memo(function BulkActionsHeader({
  selectedIds,
  setSelectedIds,
  resourceName,
  onCheckedChange,
  isChecked,
  isMinusIcon,
  children,
}: Props) {
  const selectedText = useMemo(() => {
    if (!selectedIds.length) {
      return '';
    }

    return `${selectedIds.length} ${pluralize(resourceName, selectedIds.length)} selected`;
  }, [selectedIds, resourceName]);

  return (
    <div className={styles.bulkActionsHeader}>
      <div className={styles.left}>
        <Checkbox
          labelId="select-or-deselect-all"
          isChecked={isChecked}
          onCheckedChange={onCheckedChange}
          isMinusIcon={isMinusIcon}
        />
        {selectedIds.length > 0 && (
          <div className={styles.selectedLength}>
            {selectedText}
            <Button onClick={() => setSelectedIds([])}>Clear</Button>
          </div>
        )}
      </div>

      <div className={styles.right}>{children}</div>
    </div>
  );
});

export default BulkActionsHeader;
